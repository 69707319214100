import React, { useState } from 'react'

import Layout from 'src/layouts/BaseLayout'
import HeroChequeEspecial from '../cheque-especial-sucesso/sections/hero/_index'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

import QRcodeModalContratado from './assets/images/qrcode-modal-contratado.svg'

import pageContext from './pageContext.json'

const ChequeEspecialContratado = () => {
  const urlMobileContratado = 'https://bancointer.go.link/chequeEspecial?adj_t=1brmqynw&adj_adgroup=contadigital&adj_creative=onboarding_chequeespecial_contratado&adj_fallback=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_contratado&adj_redirect_macos=https%3A%2F%2Finter.co%2Fpra-voce%2Femprestimos%2Fcheque-especial%2F%3Futm_source%3Dsite%26utm_medium%3Dsite%26utm_campaign%3Dsite_contadigital_onboarding_chequeespecial_contratado'
  const domReady = useDomReady()
  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const openModalContratado = domReady && (
    <Modal isModalOpen={isOpenModal} setIsModalOpen={setIsOpenModal} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpenModal}
        setIsModal={setIsOpenModal}
        title='Acesse o nosso Super App'
        subtitle="<span class='text-left'>Você será redirecionado para o Super App para utilizar o Cheque Especial.</span>"
        instructions="<span class='text-left'>Abra a câmera do seu celular e aponte para o QR Code acima. É rápido e fácil!</span>"
        qrCode={QRcodeModalContratado}
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {openModalContratado}
      <HeroChequeEspecial
        title='Você já contratou o Cheque Especial'
        subTitle='O Cheque Especial já está disponível pra você, acesse o Super App para utilizar.'
        setIcon='check'
        url={urlMobileContratado}
        setIsOpenModal={setIsOpenModal}
      />
    </Layout>
  )
}

export default ChequeEspecialContratado
